import PropTypes from 'prop-types';
import React     from 'react';

import { CancelARPresenter } from './cancel-ar-presenter';
import { useCancelAr,
  handleRemindLater }        from '../../../helpers/hooks/use-cancel-ar';
import { useGlobalCtx }      from '../../../context/ctx-hook';
import * as BillSelectors    from '../../../context/selectors/bill-selectors';
import * as ProductSelectors from '../../../context/selectors/product-selectors';

const getProductName = (productline) => {
  const names = {
    'acme': 'Simplifi',
    'quicken-us': 'Classic',
    'quicken-ca': 'Classic',
    'quicken-estate-manager': 'LifeHub',
    'quicken-online-backup': 'Online Backup'
  };

  return names[productline] || '';
};

const CancelAR = ({onClose, ctx: {product}}) => {
  const { state }       = useGlobalCtx();
  const CANCEL_AR       = useCancelAr(product, onClose);
  const REMIND_LATER    = handleRemindLater(product, onClose);
  const SUB             = ProductSelectors.getSubscription(state, product);
  
  const IS_SIMP         = SUB.productLineName == 'Simplifi';
  const IS_BM           = product === 'billmanager';
  const IS_QEM          = product === 'quicken-estate-manager';
  const IS_QOB          = (product === 'quicken-online-backup');
  const IS_IN_TRIAL     = SUB.isInTrialPeriod;
  const HAS_PROMO_ENT   = BillSelectors.getBMHasPromoEntitlement(state);
  const HAS_REG_ENT     = BillSelectors.getBMHasEntitlement(state, ['BILLPAY_STANDARD']);
  const HAS_ENTITLEMENT = HAS_PROMO_ENT || HAS_REG_ENT;
  let MESSAGE           = '';
  let IS_STANDARD       = false;
  let productLineName   = getProductName(product);

  if (HAS_ENTITLEMENT) {
    if (HAS_REG_ENT) {
      IS_STANDARD = BillSelectors.getBMHasEntitlement(state, ['BILLPAY_STANDARD']);
    }
    if (HAS_PROMO_ENT) {
      const PROMO_ENT = ProductSelectors.getSubscription(state, 'billmanager-promotion');
      IS_STANDARD = PROMO_ENT.tierUriName === 'standard';
    }
  }
  
  const { q_payments, c_payments} = {
    c_payments: IS_STANDARD ? 5 : 9,
    q_payments: 12
  };
  
  if (IS_BM && !HAS_ENTITLEMENT) {
    MESSAGE = 'Once your membership expires, you will no longer be able to schedule Quick Pay & Check Pay payments from your Quicken application';
  } else if (IS_BM && HAS_ENTITLEMENT) {
    MESSAGE = `Once your membership expires, you will only be able to make ${q_payments} Quick Pay payments and ${c_payments} Check Pay payments per month.`;
  } else if (IS_SIMP){
    MESSAGE = `Once your membership expires, you will no longer have the convenience of Quicken ${ProductSelectors.simplifiTierDisplayName(SUB.tierName)} connected services including:`;
  } else if (IS_QEM){
    MESSAGE = ( IS_IN_TRIAL ? 
      `Once your free trial expires, you’ll no longer have access to Quicken ${productLineName}, including:`:
      `Once your membership expires, you’ll no longer have access to Quicken ${productLineName}, including:`);
  } else if (IS_QOB) {
    MESSAGE = 'Once your membership expires, you will no longer have access to Quicken Online Backup connected services, including: ';
  } else {
    MESSAGE = `Once your membership expires, you will no longer have the convenience of Quicken ${productLineName} connected services including:`;
  }

  const POINTS = ProductSelectors.getArCancelProductPoints(SUB.tierUriName);

  return (
    <CancelARPresenter
      product={product}
      message={MESSAGE}
      points={POINTS}
      onClose={onClose}
      onSubmit={CANCEL_AR}
      remindLaterHandler={REMIND_LATER}
    />
  );
};

CancelAR.displayName = 'CancelAR';
CancelAR.propTypes   = {
  onClose: PropTypes.func.isRequired,
  ctx:     PropTypes.shape({
    product: PropTypes.string.isRequired
  })
};

export { CancelAR };
